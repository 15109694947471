import init from 'init'

function checkboxTree(e, inserted = null) {
  $(inserted ? inserted : document)
    .find('.checkbox_tree_root input[type="checkbox"]')
    .each(toggleCheckboxes)
    .each(function() {
      $(this).on('change', toggleCheckboxes)
    })
}

function toggleCheckboxes() {
  if (this.checked) {
    $(this).parent('li').find('.checkbox_tree input[type="checkbox"]').each(function() { this.checked = true })
  } else {
    $(this).parents('li').find('> input[type="checkbox"]').each(function() { this.checked = false })
  }
}

init(checkboxTree)
