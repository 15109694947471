import init from 'init'

init(function () {
  $('[data-toggle="popover"]').each(function() {
    let elem = $(this),
      content_element = elem.data('contentElement'),
      options = {}

    if (content_element) options['content'] = $(elem.data('contentElement')).html()
    elem.popover(options)
  })
})
