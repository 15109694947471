function toggle_conditional_fields(conditional_fields, force_hidden = false) {
  const elem = $(this)
  conditional_fields.each(function() {
    const field = $(this),
      conditional_value = field.data('conditionalValue')

    if (field.data('disabled') == undefined) field.data('disabled', this.hasAttribute('disabled'))
    !force_hidden && matches_condition(elem, conditional_value) ? show_field(field) : hide_field(field)
  })
}

function matches_condition(elem, conditional_value) {
  if (elem.attr('type') == 'checkbox') {
    let checked = []
    $(`[name="${elem.attr('name')}"]:checked`).each((_, checkbox) => checked.push(checkbox.value))
    return checked.includes(conditional_value)
  } else {
    return elem.val() == conditional_value
  }
}

function hide_field(field) {
  const form_group = field.parents('.form-group')
  field.trigger('app:hide')
  form_group.slideUp(400, () => field.attr('disabled', true))
}

function show_field(field) {
  const form_group = field.parents('.form-group')
  field.attr('disabled', field.data('disabled') || false)
  field.trigger('app:show')
  form_group.slideDown(400)
}

function init_conditional_fields() {
  $('[data-metric-id]').each(function() {
    const elem = $(this),
      metric_id = elem.data('metricId'),
      conditional_fields = $(`[data-conditional-on="${metric_id}"]`)

    toggle_conditional_fields.call(this, conditional_fields)
    elem.on('input change app:show', function() { toggle_conditional_fields.call(this, conditional_fields) })
    elem.on('app:hide', function() { toggle_conditional_fields.call(this, conditional_fields, true) })
  })
}

function init_copy_link() {
  $('.copy_last_period').on('click', function(e) {
    e.preventDefault()
    const elem = $(this),
      previous_value = elem.data('previousValue'),
      inputs = elem.parents('.form-group').find(':input:not([type=hidden])')

    if (inputs.attr('type') == 'checkbox') {
      inputs.each(function() {
        this.checked = previous_value.includes(this.value)
      })
    } else if (inputs.attr('type') == 'radio') {
      inputs.each(function() {
        this.checked = (previous_value == this.value)
      })
    } else if (inputs.is('select')) {
      inputs.val(previous_value)
    } else if (inputs.hasClass('flatpickr-input')) {
      inputs.parents('.datepicker').get(0)._flatpickr.setDate(previous_value)
    } else {
      inputs.val(previous_value)
    }
    inputs.trigger('change')
  })
}

$(init_conditional_fields)
$(init_copy_link)
