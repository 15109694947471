export function toast(body, header = null) {
  const container = $("#toast-messages"),
    toastElement = $(`
      <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header text-white bg-success">
          <strong class="mr-auto">${header ? header : "Key Metrics"}</strong>
          <button type="button" class="ml-2 mb-1 text-white close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">${body}</div>
      </div>
    `);

  toastElement.toast({ autohide: false });
  container.append(toastElement);
  toastElement.toast("show");
}
