import init from "init";
import axios from "axios";

import { toast } from "../toast";

function initBulkEntitiesDropdown(e, inserted = document) {
  $(".bulk-entities a", inserted).on("click", async function (e) {
    e.preventDefault();

    let entity_ids = $(this).data("entityIds");
    if (!entity_ids) {
      const { data } = await axios.get(this.href);
      entity_ids = data.data.map((entity) => entity.id);
    }
    entity_ids = entity_ids.map((entity) => entity.toString());

    $('[name="project[entity_ids][]"]').each(function () {
      if (!entity_ids.includes(this.value)) return;
      this.checked = true;
    });
    toast(`Entities bulk selected`);
  });
}

function initBulkProjectUsersDropdown(e, inserted = document) {
  $(".bulk-project-users a", inserted).on("click", async function (e) {
    e.preventDefault();

    const { data } = await axios.get(this.href);
    const user_ids = data.data.map((user) => user.id.toString());
    $('[name="project[user_ids][]"]').each(function () {
      if (!user_ids.includes(this.value)) return;
      this.checked = true;
    });
    toast(`Users bulk selected.`);
  });
}

function initBulkNotificationUsersDropdown(e, inserted = document) {
  $(".bulk-notification-users a", inserted).on("click", async function (e) {
    e.preventDefault();

    const { data } = await axios.get(this.href);
    const user_ids = data.data.map((user) => user.id.toString()),
      select_input = $("#notification_user_ids"),
      current_user_ids = select_input.val();
    select_input.val([...user_ids, ...current_user_ids]);
    select_input.trigger("change");
    toast(`Users bulk selected.`);
  });
}

function initBulkAddEntitiesByCustomField(e, inserted = document) {
  const bulk_custom_field_dropdown = $("#bulkCustomFieldDropdown", inserted),
    bulk_custom_field_options = $("#bulkCustomFieldOptions", inserted);

  $("#bulk_custom_field", inserted).on("change", async function () {
    bulk_custom_field_dropdown.prop("disabled", true);
    bulk_custom_field_options.empty();
    if (this.value) {
      const { data } = await axios.get(`/api/app/custom_fields/${this.value}/values`);
      data.forEach((value) => {
        const link = $(
          `<a href="${value.entities_url}" data-entity-ids="${JSON.stringify(
            value.entity_ids
          )}" class="dropdown-item">${value.text}</a>`
        );
        bulk_custom_field_options.append(link);
      });
      bulk_custom_field_options.trigger("app:after-insert", bulk_custom_field_options.parent());
      bulk_custom_field_dropdown.prop("disabled", false);
    }
  });
}

init(
  initBulkEntitiesDropdown,
  initBulkProjectUsersDropdown,
  initBulkNotificationUsersDropdown,
  initBulkAddEntitiesByCustomField
);
