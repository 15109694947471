import axios from 'axios'
import Tribute from "tributejs"
import init from 'init'

init(function() {
  let keyword_search = document.getElementById('user_keyword_search')
  if (keyword_search) {
    let tribute = new Tribute({
      autocompleteMode: true,
      replaceTextSuffix: '',
      menuItemTemplate(item) {
        return `<div class="d-flex justify-content-between"><div class="mr-2">${item.original.name}</div> <div class="text-muted">(${item.original.email})<div></div>`
      },
      values: async function(text, cb) {
        let { data } = await axios.get(`/admin/users.json?${keyword_search.name}=${text}`)
        cb(data.data)
      },
      fillAttr: 'email',
      lookup: 'name'
    })
    tribute.attach(keyword_search)
  }
})
