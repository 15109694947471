import init from "init";

init(function (e, insertedItem = document) {
  const addRow = function (ev) {
    ev.preventDefault();

    const $nearest_row = $(this).prev();
    // Show remove button in case it was hidden
    $nearest_row.find(".text-array__remove").show();

    const $new_row = $nearest_row.clone();
    $new_row.find("input, textarea").val("");

    // Need to set click handler on newly created button
    const $remove_button = $new_row.find(".text-array__remove");
    $remove_button.on("click", removeRow);

    return $new_row.insertBefore(this);
  };

  var removeRow = function (ev) {
    ev.preventDefault();

    this.parentElement.remove();
    return hideRemoveButton();
  };

  // Need to hide button so they can't remove the only row, otherwise there would be nothing to clone.
  var hideRemoveButton = () =>
    $(".text-array").each(function (i, el) {
      const $rows = $(el).children(".text-array__row");
      if ($rows.length === 1) {
        return $rows.find(".text-array__remove").hide();
      }
    });

  $(".text-array__add").on("click", addRow);
  $(".text-array__remove").on("click", removeRow);
  return hideRemoveButton();
});
