import { select2 } from "../select";

$(function() {
  select2($(".survey_select"), {
    ajax: {
      url: "/api/app/surveys",
      dataType: "json",
      data(params) {
        const project_id = $("#q_project_id_eq").val();
        return { q: { name_cont: params.term, project_id_eq: project_id } };
      },
      processResults: surveys => ({
        results: [{ id: "", text: "All Surveys" }, ...surveys.map(survey => ({ id: survey.id, text: survey.name }))]
      })
    }
  });
});
