import init from 'init'
import maskInput from 'vanilla-text-mask'
const PHONE_MASK = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

function phone_inputs(e, inserted = null) {
  $(inserted ? inserted : document).find('.phone_input').each(function() {
    maskInput({
      inputElement: this,
      mask: PHONE_MASK
    })
  })
}

init(phone_inputs)
