// utility function to initialize jQuery libraries
// on document ready
// AND
// cocoon.js [after-insert] event
// AND
// custom app:after-insert event
export default function init() {
  for (let i = 0; i < arguments.length; i++) {
    $(document).on('cocoon:after-insert', arguments[i])
    $(document).on('app:after-insert', arguments[i])
    $(arguments[i])
  }
}
