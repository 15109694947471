import init from 'init'
import Sortable from 'sortablejs'

init(function() {
  let list = document.getElementById('custom_fields')
  if (!list) return

  Sortable.create(list, {
    handle: '.sort_custom_field',
    animation: 150,
    onUpdate: function(e) {
      let position_inputs = list.querySelectorAll("[name$='[position]']")
      Array.prototype.forEach.call(position_inputs, function(input, index) {
        console.log(input, input.value, index)
        input.value = index
      })
    }
  })
})
