import init from 'init'
import axios from 'axios'

init(function() {
  $('.notification_user_alert').on('close.bs.alert', function() {
    let elem = $(this),
      id = elem.data('notificationUserId')

    axios.patch(`/notification_users/${id}`, { notification_user: { read_at: new Date } })
  })
})
