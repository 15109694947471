import init from "init";
const due_date_container_ids =
  "#survey_due_date_following_month_on_day, #survey_due_date_days_after_period_end, #survey_due_date_months_after_period_end";
const lock_date_container_ids =
  "#survey_lock_date_following_month_on_day, #survey_lock_date_days_after_period_end, #survey_lock_date_months_after_period_end";

function toggle_due_date_containers(containers, value) {
  const active_container = $(`#survey_due_date_${value}`),
    active_input = active_container.find("input");

  containers.hide();
  active_input.attr("disabled", false);
  active_container.show();
  containers
    .not(active_container)
    .find("input")
    .attr("disabled", true);
}

function bind_due_date_radios() {
  let input_containers = $(due_date_container_ids),
    selected = $("input[name='survey[due_date_type]']:checked").val();
  $("input[name='survey[due_date_type]']").on("change", function() {
    toggle_due_date_containers(input_containers, this.value);
  });
  toggle_due_date_containers(input_containers, selected);
}

function toggle_lock_date_containers(containers, value) {
  const active_container = $(`#survey_lock_date_${value}`),
    active_input = active_container.find("input");

  containers.hide();
  active_input.attr("disabled", false);
  active_container.show();
  containers
    .not(active_container)
    .find("input")
    .attr("disabled", true);
}

function bind_lock_date_radios() {
  let input_containers = $(lock_date_container_ids),
    selected = $("input[name='survey[lock_date_type]']:checked").val();
  $("input[name='survey[lock_date_type]']").on("change", function() {
    toggle_lock_date_containers(input_containers, this.value);
  });
  toggle_lock_date_containers(input_containers, selected);
}

function bind_period_dropdown() {
  $("#survey_period").on("change", () => set_periods());
}

function set_periods(preserve_values = false) {
  let period_dropdown = $("#survey_period"),
    value = period_dropdown.val(),
    start_period = $("#survey_start_period"),
    start_value = preserve_values ? start_period.val() : null,
    end_period = $("#survey_end_period"),
    end_value = preserve_values ? end_period.val() : null,
    bounding_period_groups = $(".form-group.survey_start_period, .form-group.survey_end_period");

  if (value == "monthly") {
    bounding_period_groups.removeClass("d-none");
    month_options(start_period, start_value);
    month_options(end_period, end_value, true);
  } else if (value == "quarterly") {
    bounding_period_groups.removeClass("d-none");
    quarter_options(start_period, start_value);
    quarter_options(end_period, end_value, true);
  } else if (value == "semi_annually") {
    bounding_period_groups.removeClass("d-none");
    semi_annually_options(start_period, start_value);
    semi_annually_options(end_period, end_value, true);
  } else if (value == "daily") {
    bounding_period_groups.removeClass("d-none");
    daily_options(start_period, start_value);
    daily_options(end_period, end_value, true);
  } else {
    bounding_period_groups.addClass("d-none");
    start_period.html(null);
    end_period.html(null);
  }
}

function daily_options(select, value = null, include_blank = false) {
  let options = [...Array(366).keys()].map(i => ({ value: i + 1, label: i + 1 }));
  if (include_blank) options.unshift({ label: "" });
  select.html(create_options(options));
  select.val(value);
}

function month_options(select, value = null, include_blank = false) {
  let options = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" }
  ];
  if (include_blank) options.unshift({ label: "" });
  select.html(create_options(options));
  select.val(value);
}

function quarter_options(select, value = null, include_blank = false) {
  let options = [
    { value: 1, label: "Q1" },
    { value: 2, label: "Q2" },
    { value: 3, label: "Q3" },
    { value: 4, label: "Q4" }
  ];
  if (include_blank) options.unshift({ label: "" });
  select.html(create_options(options));
  select.val(value);
}

function semi_annually_options(select, value = null, include_blank = false) {
  let options = [
    { value: 1, label: "First Half" },
    { value: 2, label: "Second Half" }
  ];
  if (include_blank) options.unshift({ label: "" });
  select.html(create_options(options));
  select.val(value);
}

function create_options(options = []) {
  return options.map(({ value, label }) => new Option(label, value));
}

init(bind_due_date_radios, bind_lock_date_radios, bind_period_dropdown, () => set_periods(true));
