import init from './init'
import flatpickr from 'flatpickr'

function initDatepicker(e, inserted = null) {
  $(inserted ? inserted : document).find('.datepicker').each(function () {
    let elem = $(this)
    let picker = flatpickr(this, {
      allowInput: true,
      dateFormat: 'm/d/Y',
      wrap: true
    })
  })
}

init(initDatepicker)
