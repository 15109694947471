import init from 'init'
const container_ids = '#entity_lookup, #system_lookup, #facility_lookup, #unit_lookup, #patient_lookup',
  lookup_query = 'input[name="surveys_import[entity_lookup_type]"]'

function toggle_lookup_dropdowns(containers, value) {
  let active_dropdown = $(`#${value}_lookup`)

  containers.hide()
  active_dropdown.show()
  containers.not(active_dropdown).find('select').val(null).trigger('change')
}

init(function() {
  let containers = $(container_ids),
    selected = $(`${lookup_query}:checked`).val()
  $(lookup_query).on('change', function() {
    toggle_lookup_dropdowns(containers, this.value)
  })
  toggle_lookup_dropdowns(containers, selected)
})
