import init from './init'

init(function() {
  $('#skip-link').click(function(e) {
    e.preventDefault()
    const header = $(':header:first')
    header.attr('tabindex', '-1').on('blur focusout', function () {
      $(this).removeAttr('tabindex')
    })
    header.focus()
  })
})
