import axios from 'axios'
import Tribute from "tributejs"
import init from 'init'

let tribute = new Tribute({
  trigger: '#',
  requireLeadingSpace: false,
  selectTemplate: item => item.original.slug,
  values: async function(text, cb) {
    let { data } = await axios.get(`/admin/metrics.json?q[label_or_slug_cont]=${text}&q[kind_eq]=quantitative&q[no_calculations]=true`)
    cb(data.data)
  },
  lookup: 'slug',
})

init(function() {
  let calculation_option = document.getElementById('metric_calculation_option')
  if (calculation_option) tribute.attach(calculation_option)
})
