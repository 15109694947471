import init from 'init'
const dropdown_container_ids = '#permission_type_users, #permission_type_organizations, #permission_type_entities, #permission_type_projects, #permission_type_surveys'

function toggle_permission_dropdowns(containers, value) {
  let active_dropdown = $(`#permission_type_${value}`)

  containers.hide()
  active_dropdown.show()
  containers.not(active_dropdown).find('select').val(null).trigger('change')
}

init(function() {
  let dropdown_containers = $(dropdown_container_ids),
    selected = $("input[name='page[permission_type]']:checked").val()
  $("input[name='page[permission_type]']").on('change', function() {
    toggle_permission_dropdowns(dropdown_containers, this.value)
  })
  toggle_permission_dropdowns(dropdown_containers, selected)
})
