import axios from "axios";

const PING_FREQUENCY = 5000,
  TIMEOUT_WARNING_SECONDS = 60;

$(function bindSessionPings() {
  let active = false,
    timeoutIn = 600,
    sessionPingInterval,
    sessionModalInterval,
    timeRemaing = 60;
  $(document).on("scroll mousemove click keydown", () => (active = true));

  async function sessionPing() {
    let { data } = await axios.post("/user/ping", { active });
    if (!data.signedIn) {
      clearInterval(sessionPingInterval);
      return;
    }
    timeoutIn = data.timeoutIn;
    active = false;

    if (timeoutIn <= 0) {
      location.reload();
    } else if (timeoutIn <= TIMEOUT_WARNING_SECONDS) {
      if (sessionModalInterval) clearInterval(sessionModalInterval);
      timeRemaing = timeoutIn;
      $("#session-timeout-seconds").text(timeRemaing);
      $("#session-timeout-modal").modal("show");
      sessionModalInterval = setInterval(() => {
        if (timeRemaing > 0) {
          $("#session-timeout-seconds").text((timeRemaing -= 1));
        } else {
          location.reload();
        }
      }, 1000);
    } else if (sessionModalInterval) {
      sessionModalInterval = clearInterval(sessionModalInterval);
      $("#session-timeout-modal").modal("hide");
    }
  }

  sessionPing();
  sessionPingInterval = setInterval(sessionPing, PING_FREQUENCY);
  $("#refresh-session").on("click", sessionPing);
});
