import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowsV,
  faBars,
  faBracketsCurly,
  faCalculator,
  faCheck,
  faCode,
  faDownload,
  faExclamationCircle,
  faHistory,
  faInfoCircle,
  faPencil,
  faTimes,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCalendarAlt,
} from '@fortawesome/pro-regular-svg-icons'


library.add(
  faArrowsV,
  faBars,
  faBracketsCurly,
  faCalculator,
  faCalendarAlt,
  faCheck,
  faCode,
  faDownload,
  faExclamationCircle,
  faHistory,
  faInfoCircle,
  faPencil,
  faTimes,
  faTrash,
)

dom.watch()
