import init from "init";

function toggle_secure_file_recipient_visibility() {
  let elem = $(this),
    secure_file_recipient = $(".user_secure_file_recipient");

  switch (elem.val()) {
    case "organization_administrator":
    case "super_administrator":
      secure_file_recipient.show();
      break;
    default:
      secure_file_recipient.hide();
      break;
  }
}

function toggle_secure_file_sender_visibility() {
  let elem = $(this),
    secure_file_sender = $(".user_secure_file_sender");

  switch (elem.val()) {
    case "organization_administrator":
    case "super_administrator":
      secure_file_sender.hide();
      break;
    default:
      secure_file_sender.show();
      break;
  }
}

init(function () {
  $("#user_role_type").each(toggle_secure_file_recipient_visibility);
  $("#user_role_type").on("change", toggle_secure_file_recipient_visibility);
  $("#user_role_type").each(toggle_secure_file_sender_visibility);
  $("#user_role_type").on("change", toggle_secure_file_sender_visibility);
});
