import init from "init";
import "select2/dist/js/select2.full";

export function select2(element, options = {}) {
  if (element.data("tags") || false) options.tokenSeparators = [","];

  if (element.hasClass("force-search")) {
    options.minimumResultsForSearch = null;
  } else if (!options.minimumResultsForSearch && element.hasClass("disable-search")) {
    options.minimumResultsForSearch = 999999999;
  } else {
    options.minimumResultsForSearch = 10;
  }

  return element.select2({
    theme: "bootstrap4",
    width: "100%",
    ...options,
  });
}

function build_select2(e, inserted = null) {
  $(inserted ? inserted : document)
    .find("select")
    .not(".native, .custom-select, .flatpickr-calendar select")
    .each(function () {
      select2($(this));
    });
}

function init_select2_all() {
  $(".select2_all").on("click", function (e) {
    e.preventDefault();
    let elem = $(this.hash);
    elem.find("option").prop("selected", true);
    elem.trigger("change");
  });
}

init(build_select2, init_select2_all);
