import init from "../init";
import { select2 } from "../select";

import axios from "axios";
import throttle from "lodash.throttle";
import Sortable from "sortablejs";
const SEARCH_FREQUENCY = 250; // 0.25 seconds

$(function () {
  const metric_fields = $("#metric-fields");
  if (!metric_fields) return;

  metric_fields.on("survey_metric:insert survey_metric:remove", update_conditional_options);
  update_conditional_options.call(metric_fields.get(0));
});

function update_conditional_options() {
  const metric_fields = $(this),
    survey_metrics = metric_fields.find(".metric_field.conditional_source:visible"),
    selects = metric_fields.find(".conditional_on_select");

  let conditional_on_options = $.map(survey_metrics, function (survey_metric) {
    const elem = $(survey_metric),
      metric_id = elem.find(".metric_id_input_survey_metric").val(),
      slug = elem.find(".metric_slug").text();
    return { id: metric_id, text: slug };
  });
  selects.each(function () {
    let elem = $(this),
      value = elem.val();
    elem.empty().append("<option></option>");
    select2(elem, { data: conditional_on_options });
    elem.val(value).trigger("change");
  });
}

function build_metrics_dropdown(e, inserted = null) {
  $(inserted ? inserted : document)
    .find(".metrics-dropdown")
    .on("show.bs.dropdown", load_metrics)
    .on("shown.bs.dropdown", search_focus);
  $(inserted ? inserted : document)
    .find(".metrics-dropdown input.metric_search")
    .on("input", throttle(load_metrics, SEARCH_FREQUENCY));
}

function initialize_metric_fields(e, inserted = null) {
  $(inserted ? inserted : document)
    .find(".metric_field")
    .each(function () {
      bind_metric_fields($(this));
    });
  let list = document.getElementById("metric-fields");
  if (!list) return;

  Sortable.create(list, {
    handle: ".sort_survey_metric",
    ghostClass: "metric_field_ghost",
    animation: 150,
    onUpdate: function (e) {
      let position_inputs = list.querySelectorAll("[name$='[position]']");
      Array.prototype.forEach.call(position_inputs, function (input, index) {
        input.value = index;
      });
    },
  });
}

async function load_metrics(e) {
  let metric_dropdown = $(this).closest(".metrics-dropdown"),
    metric_options = metric_dropdown.find(".metric_options"),
    search_input = metric_dropdown.find("input.metric_search"),
    metric_fields = $("#metric-fields"),
    ransack_query = {
      label_or_slug_cont: search_input.val(),
      id_not_in: [],
      status_eq: "enabled",
    };

  if (search_input.data("kind")) ransack_query["kind_eq"] = search_input.data("kind");
  metric_fields.find(".metric_field:visible .metric_id_input_survey_metric").each(function () {
    ransack_query["id_not_in"].push(this.value);
  });

  let { data } = await axios.post("/admin/metrics/search.json", { q: ransack_query });
  metric_options.html(
    data.data.map((metric) => {
      let button = $(`
      <button class="dropdown-item" type="button">
        <div class="d-flex justify-content-between align-items-center">
          <span>${metric.label}</span>
          <small class="ml-3 text-muted text-monospace">[<code>${metric.slug}</code>]</small>
        </div>
      </button>
    `);
      button.on("click", async function () {
        search_input.val("");
        let { data } = await axios.get(`metrics/${metric.id}.html`),
          metric_field = $(data);
        bind_metric_fields(metric_field);
        metric_fields.append(metric_field);
        metric_field.trigger("survey_metric:insert");
        $(document).trigger("app:after-insert", metric_field);
      });
      return button;
    })
  );
  metric_dropdown.find(".dropdown-toggle").dropdown("update");
}

function search_focus() {
  $(this).find("input.metric_search").focus();
}

function bind_metric_fields(metric_field) {
  metric_field.find(".destroy_survey_metric").on("click", function (e) {
    metric_field.addClass("d-none");
    metric_field.find(".destroy_input_survey_metric").val("1");
    metric_field.trigger("survey_metric:remove");
    e.preventDefault();
  });
}

init(build_metrics_dropdown, initialize_metric_fields);
