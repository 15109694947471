import Trix from "trix";

Trix.config.attachments.preview.caption = {
  name: false,
  size: false,
};

addEventListener("trix-initialize", function (event) {
  new RichText(event.target);
});

class RichText {
  constructor(element) {
    this.element = element;

    this.addTrixFileUploadEventListener();
    if ("customHtml" in this.element.dataset) this.initializeCustomHtml();
    if ("customTags" in this.element.dataset) this.initializeCustomTags();
  }

  initializeCustomHtml() {
    this.insertCustomHtmlButton();
    this.insertCustomHtmlDialog();
    this.addCustomHtmlEventListener();
  }

  initializeCustomTags() {
    this.customTags = JSON.parse(this.element.dataset.customTags) || {};
    this.insertCustomTagsButton();
    this.insertCustomTagsDialog();
    this.addCustomTagsEventListener();
  }

  uploadAttachment(attachment) {
    let csrfToken = $('meta[name="csrf-token"]').attr("content"),
      file = attachment.file,
      form = new FormData(),
      endpoint = "/trix_images",
      xhr = new XMLHttpRequest();

    form.append("Content-Type", file.type);
    form.append("trix_image[image]", file);

    xhr.open("POST", endpoint, true);
    xhr.setRequestHeader("X-CSRF-Token", csrfToken);

    xhr.upload.onprogress = function (event) {
      let progress = (event.loaded / event.total) * 100;
      return attachment.setUploadProgress(progress);
    };

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        let data = JSON.parse(this.responseText);
        return attachment.setAttributes({
          url: data.url,
          href: data.url,
        });
      }
    };

    return xhr.send(form);
  }

  addTrixFileUploadEventListener() {
    this.element.addEventListener("trix-attachment-add", (event) => {
      let attachment = event.attachment;
      if (attachment.file) return this.uploadAttachment(attachment);
    });
  }

  insertCustomHtmlButton() {
    this.buttonGroupBlockTools.insertAdjacentHTML(
      "afterend",
      `
      <span class="trix-button-group trix-button-group--custom-html">
        <button type="button" class="trix-button trix-button--icon trix-button--fa" data-trix-action="custom-html" title="Add HTML" tabindex="-1"><i class="fas fa-code"></i></button>
      </span>
    `
    );
  }

  insertCustomHtmlDialog() {
    this.dialogsElement.insertAdjacentHTML(
      "beforeend",
      `
        <div class="trix-dialog trix-dialog--custom-html" data-trix-dialog="custom-html">
          <div class="trix-dialog__custom-html-fields">
            <textarea class='form-control form-control-sm mb-2 trix_custom_html_content' rows="3"></textarea>
            <button class="btn btn-primary btn-sm btn-block" data-trix-action="x-insert-custom-html" title="Insert HTML">Insert HTML</button>
          </div>
        </div>
      `
    );
  }

  addCustomHtmlEventListener() {
    this.element.addEventListener("trix-action-invoke", (event) => {
      if (event.actionName !== "x-insert-custom-html") return;

      const textarea = this.toolbarElement.querySelector(".trix_custom_html_content"),
        content = textarea.value,
        trix_tag = new Trix.Attachment({ content });

      this.toolbarController.hideDialog();
      this.element.editor.insertAttachment(trix_tag);
      textarea.value = "";
    });
  }

  insertCustomTagsButton() {
    this.buttonGroupBlockTools.insertAdjacentHTML(
      "afterend",
      `
        <span class="trix-button-group trix-button-group--custom-tags">
          <button type="button" class="trix-button trix-button--icon trix-button--fa" data-trix-action="custom-tags" title="Add Template Tag" tabindex="-1"><i class="fas fa-brackets-curly"></i></button>
        </span>
      `
    );
  }

  insertCustomTagsDialog() {
    const custom_tag_buttons = Object.entries(this.customTags)
      .map(
        ([tag, label]) =>
          `<button type="button" class="trix-button trix-button--dialog" data-trix-action="x-insert-custom-tag-${tag}" title="${label}">${label}</button>`
      )
      .join("");

    this.dialogsElement.insertAdjacentHTML(
      "beforeend",
      `
        <div class="trix-dialog trix-dialog--custom-tags" data-trix-dialog="custom-tags">
          <div class="trix-button-group">${custom_tag_buttons}</div>
        </div>
      `
    );
  }

  addCustomTagsEventListener() {
    this.element.addEventListener("trix-action-invoke", (event) => {
      const matches = event.actionName.match(/x-insert-custom-tag-([\w_]+)/);
      if (matches === null) return;

      const [_m, tag] = matches,
        content = `<span class="badge badge-info trix-badge">${this.customTags[tag]}</span>`,
        trix_tag = new Trix.Attachment({ content, tag, frozen: false });

      this.toolbarController.hideDialog();
      this.element.editor.insertAttachment(trix_tag);
    });
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=block-tools]");
  }

  get buttonGroupTextTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=text-tools]");
  }

  get dialogsElement() {
    return this.toolbarElement.querySelector("[data-trix-dialogs]");
  }

  get toolbarElement() {
    return this.element.toolbarElement;
  }

  get toolbarController() {
    return this.element.editorController.toolbarController;
  }
}
