import init from 'init'

init(function() {
  $('input[type="checkbox"].bulk_action_all').on('change', function() {
    $(this).parents('table').find('.bulk_action_checkbox').prop('checked', this.checked)
  })

  $('form.bulk_action button[type="submit"]').on('click', function(e) {
    e.preventDefault()
    $(this).parents('form').attr('action', this.value).submit()
  })
})
