import "styles/application.scss";

import "jquery-ujs";
import "bootstrap";
import "chartkick/chart.js";
import "@nathanvda/cocoon";

import "checkbox_tree";
import "datepicker";
import "file_input";
import "flash-alerts-animation";
import "fontawesome";
import "forms/bulk_action";
import "forms/bulk_checkbox_select";
import "forms/calculation";
import "forms/custom_fields";
import "forms/metrics";
import "forms/notification_user";
import "forms/pages";
import "forms/projects";
import "forms/submissions";
import "forms/submission_search";
import "forms/surveys";
import "forms/survey_exports";
import "forms/survey_imports";
import "forms/survey_metrics";
import "forms/user";
import "forms/user_search";
import "page_tracking";
import "phone_input";
import "popover";
import "select";
import "session_ping";
import "skip_link";
import "tooltips";
import "trix";
import "trix_attachments";

import axios from "axios";
import LocalTime from "local-time";

// require("@rails/activestorage").start()
// require("channels")

axios.defaults.headers.common["Accept"] = "application/json";
const tokenDom = document.querySelector("meta[name=csrf-token]");
if (tokenDom) {
  const csrfToken = tokenDom.content;
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
}
LocalTime.start();
