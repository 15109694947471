import init from "init";

function toggleDropdown(dropdownContainer, value) {
  const dropdown = dropdownContainer.find("select");
  if (value == "false") {
    dropdown.find("option").prop("selected", false);
    dropdown.trigger("change");
    dropdownContainer.show();
  } else {
    dropdownContainer.hide();
    dropdown.find("option").prop("selected", true);
    dropdown.trigger("change");
  }
}

init(function (e, insertedItem = document) {
  $(insertedItem)
    .find(".survey-exports-select-all-radio")
    .each(function () {
      const elem = $(this),
        radios = elem.find("input"),
        dropdownContainer = elem.siblings(elem.data("selectContainer"));

      radios.on("change", function () {
        toggleDropdown(dropdownContainer, this.value);
      });
      elem.find("input:checked").val() ? dropdownContainer.show() : toggleDropdown(dropdownContainer, true);
    });
});
