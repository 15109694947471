import init from 'init'
import bsCustomFileInput from 'bs-custom-file-input'
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'

async function uppy_form_sumission(e) {
  e.preventDefault()
  const submit = () => $(this).unbind('submit').submit(),
    file_inputs =  $(this).find('.custom-file-input')

  if (file_inputs.length < 1) return submit()
  await Promise.all(file_inputs.map(uppy_input))

  submit()
}

function uppy_input() {
  const file_input = $(this),
    hidden_input = file_input.parents('.form-group').find('input[type="hidden"]'),
    uppy = new Uppy({ id: this.id })
  uppy.use(AwsS3Multipart, { companionUrl: '/' })

  uppy.on('upload-success', function(file, response) {
    let uploaded_file_data = JSON.stringify({
      id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size:      file.size,
        filename:  file.name,
        mime_type: file.type,
      }
    })

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    hidden_input.val(uploaded_file_data)
  })

  uppy.reset()
  const files = Array.from(this.files)
  files.forEach((file) => {
    try {
      uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file
      })
    } catch (err) {
      // if (err.isRestriction) {
      //   console.log('Restriction error:', err)
      // } else {
      //   console.error(err)
      // }
    }
  })
  return uppy.upload()
}


init(function() {
  bsCustomFileInput.init()
  $('form').on('submit', uppy_form_sumission)
})
